// Copyright (C) 2023 TANNER AG

import { apiBaseUrl, apiRoutes } from "../utils";
import useIntervalReload from "./useintervalreload";
import useAdminMode from "./useadminmode";
import { ADMIN_HEADER_NAME } from "../types";

const useFetcher = () => {
    const { resetInterval } = useIntervalReload();
    const adminModeEnabled = useAdminMode();

    return (input: RequestInfo, init?: RequestInit) => {
        resetInterval();
        return fetch(apiBaseUrl + input, {
            ...init,
            headers: {
                ...(adminModeEnabled && { [ADMIN_HEADER_NAME]: "true" }),
                ...init?.headers
            },
            credentials: "include"
        }).then((response) => {
            if (response.status === 401) {
                window.location.href = apiBaseUrl + apiRoutes.login;
            }
            return response;
        });
    };
};

export default useFetcher;
